<template>
	<w-layout>
		<w-flex>
			<w-text-input
				v-model="application.bundle_id"
				:disabled="!canEdit"
				auto-focus
				color="primary"
				:placeholder="bundleIdPlaceHolder"
				:label="bundleIdFieldName"
			></w-text-input>
		</w-flex>
	</w-layout>
</template>
<script>
export default {
	name: 'MobileAppBundleID',
	props: {
		value: {
			required: true,
			type: Object
		},
		canEdit: {
			required: false,
			type: Boolean,
			default: true
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		bundleIdPlaceHolder: function () {
			return this.$t('mobileapp.fields.bundle_id.placeholder')
		},
		bundleIdFieldName: function () {
			return this.$t('mobileapp.fields.bundle_id.name')
		}
	}
}
</script>
